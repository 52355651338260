import axios from "axios";

export const getVendorsService = async () => {
    const response = await axios.get('kontraktor');
    return response.data;
};

export const addVendorService = async (vendor) => {
    const response = await axios.post('/v2/vendors', vendor);
    return response.data;
}

export const updateVendorService = async (vendor) => {
    const response = await axios.put(`v2/vendors/${vendor.id}`, vendor);
    return response.data;
}

export const getUsersByCategory = async (categoryId) => {
    const response = await axios.get(`v2/categories/${categoryId}/users`);
    return response.data;
}

export const getAllUsers = async () => {
    const response = await axios.get('users?without_pagination=true');
    return response.data;
}

export const changePasswordService = async (data) => {
    const response = await axios.put(`v2/change-password`, data);
    return response.data;
}