<template>
  <AppCard :shadow="false">
    <template #title> Data Master > Data User </template>
    <AppTableHeader
      @set-active="setFilterActive"
      @search="setFilterSearch"
      @direction="setFilterDirection"
      :active="filterActive"
      :menuList="headerList"
    >
      <AppButton @click="toggleAddModal">
        <AppIcon name="PlusCircle" classTag="m-auto h-6 w-6"></AppIcon>
        <h5 class="m-auto ">Tambah User</h5>
      </AppButton>
    </AppTableHeader>
    <AppTable
      :key="dataList"
      :loading="store.loading"
      :headerList="headerList"
      :currentSort="filterActive"
      :currentSortDir="filterDirection"
      :search="filterSearch"
      :action="true"
      :bodyList="dataList"
    >
      <template #action="{ data }">
        <TableActionDataMaster
          @edit-button="toggleEditModal(data)"
          @erase-button="toggleEraseModal(data.id)"
        />
      </template>
    </AppTable>
    <TableModal
      title="User"
      :add="addModal"
      @add-submit="addUser"
      @add-close="toggleAddModal"
      :edit="editModal"
      @edit-submit="updateUser"
      @edit-close="toggleEditModal"
      :erase="eraseModal"
      @erase-submit="deleteUser"
      @erase-close="toggleEraseModal"
    >
      <AppInput title="Nama User" v-model="formInput.name" :isRequired="true" />
      <AppInput
        title="Username"
        v-model="formInput.username"
        :isRequired="true"
      />
      <AppInput
        title="Email"
        v-model="formInput.email"
        :isRequired="true"
        type="email"
      />
      <AppSelect
        title="Role"
        v-model="formInput.role_id"
        :isRequired="true"
        :options="roleOptions"
      />
      <AppInput
        title="Password"
        v-model="formInput.password"
        :isRequired="addModal.open"
        type="password"
      />
    </TableModal>
  </AppCard>
</template>
<script setup>
import AppCard from "@/components/AppCard.vue";
import AppTableHeader from "@/components/AppTableHeader.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppTable from "@/components/AppTable.vue";
import TableModal from "@/components/TableModal.vue";
import TableActionDataMaster from "@/components/TableActionDataMaster.vue";
import useFilter from "@/composable/useFilter";
import useModal from "@/composable/useModal";
import { onMounted, ref } from "vue";
import { useUserStore } from "@/store/user";
import { useRoleStore } from "@/store/role";
import AppInput from "@/components/AppInput.vue";
import AppSelect from "@/components/AppSelect.vue";

const store = useUserStore();
const role = useRoleStore();

const {
  filterActive,
  filterDirection,
  filterSearch,
  setFilterActive,
  setFilterDirection,
  setFilterSearch,
} = useFilter();

const {
  formInput,
  addModal,
  toggleAddModal,
  editModal,
  toggleEditModal,
  eraseModal,
  toggleEraseModal,
} = useModal();

const headerList = [
  {
    classTag: "w-40 text-left pl-20",
    title: "nomor",
    id: "nomor",
  },
  {
    classTag: "text-left",
    title: "Nama",
    id: "name",
  },
  {
    classTag: "text-left",
    title: "Username",
    id: "username",
  },
  {
    classTag: "text-left truncate",
    title: "Email",
    id: "email",
  },
  {
    classTag: "text-left",
    title: "Role",
    id: "role_name",
  },
];

const dataList = ref([]);

onMounted(() => {
  store.fetchUsers();
  role.fetchRole();
});

store.$subscribe((mutation, state) => {
  if (!state.loading) {
    dataList.value = store.getUsers;
  }

  if (state.modalIsClose) {
    if(state.modalTitle === "DELETE_USER") {
      eraseModal.open = false;
    }
    if(state.modalTitle === "EDIT_USER") {
      editModal.open = false;
    }
    if(state.modalTitle === "ADD_USER") {
      addModal.open = false;
    }
  }
});

const roleOptions = ref({});

role.$subscribe((mutation, state) => {
  if (!state.loading) {
    roleOptions.value = role.getRolesForm;
  }
  if (!state.loadingData) {
    addModal.open = false;
    editModal.open = false;
    eraseModal.open = false;
  }
});

const addUser = () => {
  if(roleOptions.value.find(o => o.title === 'Pengawas').value === formInput.value.role_id){
    formInput.value.categories = [2, 3, 5, 7]
  }
  store.addUser(formInput.value)
};
const updateUser = () => store.updateUser(formInput.value);
const deleteUser = (id) => store.deleteUser(id);
</script>
